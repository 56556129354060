import React from 'react';
import PropTypes from 'prop-types';
import { getHTag } from 'utils/get-tag';
import Icon from 'icon';

import s from './headline.module.scss';

function Headline(props) {
  const {
    h1,
    h3,
    h2,
    html,
    el,
    primary,
    secondary,
    white,
    black,
    center,
    right,
    wrap,
    dash,
    dashLeft,
    dashCenter,
    smallCenter,
    bold,
    reg,
    ...other
  } = props;
  const primaryClasses = primary ? s.primary : '';
  const secondaryClasses = secondary ? s.secondary : '';
  const whiteClasses = white ? s.white : '';
  const blackClasses = black ? s.black : '';
  const boldClasses = bold ? s.bold : '';
  const regClasses = reg ? s.reg : '';
  const fontClasses = boldClasses || regClasses || '';
  const colorClasses =
    primaryClasses || secondaryClasses || whiteClasses || blackClasses;
  const centerClass = center ? s.center : '';
  const rightClass = right ? s.right : '';
  const wrapClass = wrap ? s.wrap : '';
  const positionClasses = `${centerClass} ${rightClass} ${wrapClass} ${
    smallCenter ? s.smallCenter : ''
  }`;
  const getElement = () => {
    if (h1) return 'h1';
    return h2 ? 'h2' : 'h3';
  };
  const elementTag = el || getElement();
  const defaultClasses = `${
    s[getElement()]
  } ${colorClasses} ${positionClasses} ${fontClasses} ${dash ? s.hasDash : ''}`;
  const headlineProps = {
    ...other,
    className: `${defaultClasses} ${props.className || ''}`
  };
  const Tag = html ? getHTag(html).element || elementTag : elementTag;
  const innerHtml = (html && getHTag(html).body) || html;
  if (html) {
    return dash ? (
      <Tag {...headlineProps}>
        <span className={s.wrapper}>
          <span dangerouslySetInnerHTML={{ __html: `${innerHtml}` }} />
          {dash && (
            <Icon
              icon="dash"
              className={`headline-dash ${s.dash} ${
                dashLeft ? s.dashLeft : ''
              } ${dashCenter ? s.dashCenter : ''}`}
            />
          )}
        </span>
      </Tag>
    ) : (
      <Tag
        {...headlineProps}
        dangerouslySetInnerHTML={{ __html: `${innerHtml}` }}
      />
    );
  }
  return dash ? (
    <Tag {...headlineProps}>
      <span className={s.wrapper}>
        <span>{props.children}</span>
        {dash && (
          <Icon
            icon="dash"
            className={`headline-dash ${s.dash} ${dashLeft ? s.dashLeft : ''} ${
              dashCenter ? s.dashCenter : ''
            }`}
          />
        )}
      </span>
    </Tag>
  ) : (
    <Tag {...headlineProps}>{props.children}</Tag>
  );
}

const validateProp = (props, name, component) => {
  if (!props.h1 && !props.h2 && !props.h3) {
    return new Error(
      `Please specify either h1, h2 or h3 prop in ${component}'.`
    );
  }
  if (props[name] && typeof props[name] !== 'boolean') {
    return new Error(`Prop ${name} in ${component} must be a boolean value.`);
  }
  return null;
};

Headline.propTypes = {
  h1: validateProp,
  h2: validateProp,
  h3: validateProp,
  el: PropTypes.string
};

export default Headline;
