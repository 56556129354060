export const getHTag = tag => {
  const hasHeadingTag = tag.slice(0, 2).includes('<h');
  const element = hasHeadingTag ? parseInt(tag.slice(2), 10) : null;
  const elementTag = element && element < 7 && element;
  const body = hasHeadingTag ? tag.slice(4, tag.length - 5) : null;
  return {
    element: elementTag ? `h${elementTag}` : null,
    body
  };
};

export default tag => (tag.includes('<p>') ? 'div' : 'p');
